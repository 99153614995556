// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-africa-availability-tsx": () => import("./../../../src/pages/africa/availability.tsx" /* webpackChunkName: "component---src-pages-africa-availability-tsx" */),
  "component---src-pages-africa-fast-accelerator-index-tsx": () => import("./../../../src/pages/africa/fast-accelerator/index.tsx" /* webpackChunkName: "component---src-pages-africa-fast-accelerator-index-tsx" */),
  "component---src-pages-africa-fast-accelerator-office-apply-tsx": () => import("./../../../src/pages/africa/fast-accelerator/office-apply.tsx" /* webpackChunkName: "component---src-pages-africa-fast-accelerator-office-apply-tsx" */),
  "component---src-pages-africa-fast-accelerator-office-space-tsx": () => import("./../../../src/pages/africa/fast-accelerator/office-space.tsx" /* webpackChunkName: "component---src-pages-africa-fast-accelerator-office-space-tsx" */),
  "component---src-pages-africa-focus-agriculture-tsx": () => import("./../../../src/pages/africa/focus/agriculture.tsx" /* webpackChunkName: "component---src-pages-africa-focus-agriculture-tsx" */),
  "component---src-pages-africa-focus-education-tsx": () => import("./../../../src/pages/africa/focus/education.tsx" /* webpackChunkName: "component---src-pages-africa-focus-education-tsx" */),
  "component---src-pages-africa-focus-healthcare-tsx": () => import("./../../../src/pages/africa/focus/healthcare.tsx" /* webpackChunkName: "component---src-pages-africa-focus-healthcare-tsx" */),
  "component---src-pages-africa-focus-industrial-tsx": () => import("./../../../src/pages/africa/focus/industrial.tsx" /* webpackChunkName: "component---src-pages-africa-focus-industrial-tsx" */),
  "component---src-pages-africa-index-tsx": () => import("./../../../src/pages/africa/index.tsx" /* webpackChunkName: "component---src-pages-africa-index-tsx" */),
  "component---src-pages-africa-laboratory-tsx": () => import("./../../../src/pages/africa/laboratory.tsx" /* webpackChunkName: "component---src-pages-africa-laboratory-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-source-tsx": () => import("./../../../src/pages/open-source.tsx" /* webpackChunkName: "component---src-pages-open-source-tsx" */),
  "component---src-pages-partners-academic-tsx": () => import("./../../../src/pages/partners/academic.tsx" /* webpackChunkName: "component---src-pages-partners-academic-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-partners-industry-tsx": () => import("./../../../src/pages/partners/industry.tsx" /* webpackChunkName: "component---src-pages-partners-industry-tsx" */),
  "component---src-pages-partners-join-tsx": () => import("./../../../src/pages/partners/join.tsx" /* webpackChunkName: "component---src-pages-partners-join-tsx" */),
  "component---src-pages-platforms-coral-imaging-tsx": () => import("./../../../src/pages/platforms/coral-imaging.tsx" /* webpackChunkName: "component---src-pages-platforms-coral-imaging-tsx" */),
  "component---src-pages-platforms-index-tsx": () => import("./../../../src/pages/platforms/index.tsx" /* webpackChunkName: "component---src-pages-platforms-index-tsx" */),
  "component---src-pages-platforms-measure-tsx": () => import("./../../../src/pages/platforms/measure.tsx" /* webpackChunkName: "component---src-pages-platforms-measure-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

